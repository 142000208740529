import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import fallbackImage from "../../images/blog-generic-cover.png"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const BlogListItem = ({ item }) => (
  <ItemWrapper to={`/newsroom/${item.slug}`}>
    <ImageBox>
      {item.featuredImage ? (
        <Img
          fluid={item.featuredImage.node.localFile.childImageSharp.fluid}
          alt={item.title}
        />
      ) : (
        <img src={fallbackImage} alt="" />
      )}
    </ImageBox>
    <ContentBox>
      <PostLink>
        <h3>{item.title}</h3>
      </PostLink>
      <p dangerouslySetInnerHTML={{ __html: item.excerpt }} />
      <div className="categories">
        {item.categories.nodes.map((item2) => {
          return <p key={item2.name}>{item2.name}</p>
        })}
      </div>
      <div className="author-box">
        <img src={item.author.node.avatar.url} alt={item.author.node.name} />
        <div>
          <p>
            {item.author.node.name} <br />
            {item.date}
          </p>
        </div>
      </div>
    </ContentBox>
  </ItemWrapper>
)
export default BlogListItem

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const PostLink = styled.div`
  :hover h3 {
    cursor: pointer;
    color: ${(props) => props.theme.green};
  }
`
const ItemWrapper = styled(Link)`
  border-radius: 5px;
  overflow: hidden;
  background-color: #eeeeee;
  display: block;
  transition: all 0.2s ease-in-out;
  color: #fff;
  text-align: left;
  transition: all 0.15s ease;
  :hover {
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  }
`
const ImageBox = styled.div`
  img {
    width: 100%;
    margin-bottom: 0px;
    display: block;
    right: 0;
    left: auto !important;
  }
`
const ContentBox = styled.div`
  padding: 20px;
  h3 {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 10px;
    color: #343434;
  }
  p {
    color: #bd8b00;
    min-height: 46px;
    font-size: 16px;
    margin-bottom: 0px;
    color: #343434;
  }
  .categories a {
    color: ${(props) => props.theme.green};
    background-color: #fff;
    border-radius: 5px;
    margin: 5px 10px 5px 0;
    padding: 5px;
    display: inline-block;
    &:hover {
      background-color: ${(props) => props.theme.green};
      color: #fff;
    }
  }
  .categories p {
    color: ${(props) => props.theme.green};
    background-color: #fff;
    border-radius: 5px;
    margin: 5px 10px 5px 0;
    padding: 5px !important;
    display: inline-block;
    min-height: 5px;
  }
  .categories {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .author-box {
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 12px;
    p {
      margin: 0;
    }
    img {
      width: 50px;
      border: 2px solid ${(props) => props.theme.gold};
      border-radius: 50%;
      margin: 0;
    }
  }
`
