import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const HomeService = ({ item }) => (
  <ItemWrapper
    bg={item.acfPageHeader.headerImage.sourceUrl}
    to={`/services/${item.slug}`}
  >
    <ContentBox bg={item.acfPageHeader.headerImage.sourceUrl}>
      <div className="service-inner">
        <div></div>
        <h3>{item.title}</h3>
        <div></div>
      </div>
    </ContentBox>
  </ItemWrapper>
)
export default HomeService

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const ItemWrapper = styled(Link)`
  display: grid;
  align-items: end;
  border: 1px solid ${(props) => props.theme.green};
  background-color: #5c5d5dd9;
  background-image: url(${(props) => props.bg});
  background-position: center;
  background-size: cover;
  border-radius: 5px;
  transition: all 0.15s ease;
  :hover {
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  }
`
const ContentBox = styled.div`
  display: grid;
  align-items: center;
  align-self: center;
  height: 100%;
  background-color: #0000006b;
  h3 {
    font-weight: 600;
    font-size: 24px;
    color: #fff;
    margin: 0px;
    text-align: center;
    font-weight: 300;
    text-transform: uppercase;
    line-height: 1.3;
  }
  .service-inner {
    display: grid;
    grid-template-rows: 100px;
    grid-template-columns: auto 340px auto;
    align-items: center;
  }
`
