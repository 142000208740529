import React from "react"
import styled from "styled-components"
import posed from "react-pose"
import ButtonArrow from "./btnArrow"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////

const SliderControls = ({
  next,
  prev,
  slug,
  mode,
  title,
  start,
  totalSlides,
  currentSlideNum,
}) => (
  <Controls className="controls" mode={mode}>
    <div className="progress-bg">
      <div className="barInner">
        <h2 dangerouslySetInnerHTML={{ __html: title }} />
        <h2 className="slide-number">
          {minTwoDigits(currentSlideNum + 1)}{" "}
          <span className="lighter">/ {minTwoDigits(totalSlides)}</span>
        </h2>
      </div>
      <ProgressBar
        className="progress-bar"
        pose={start ? "start" : "end"}
        onPoseComplete={next}
      />
    </div>
    <div className="controls-bottom">
      <div className="contolButton">
        <a href={"/services/" + slug}>FIND OUT MORE</a>
      </div>
      <div className="arrows">
        <ButtonArrow direction="p" slideShowClick={prev} />
        <ButtonArrow direction="n" slideShowClick={next} />
      </div>
    </div>
  </Controls>
)
export default SliderControls

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
function minTwoDigits(n) {
  return (n < 10 ? "0" : "") + n
}

const ProgressBar = posed.div({
  start: { width: "0%" },
  end: { width: "100%", transition: { duration: 4700, ease: "linear" } },
})

const Controls = styled.div`
  .progress-bg {
    background: #072324;
    height: 70px;
    margin: 15px 0 0px 0;
    position: relative;
    z-index: 1;
    .progress-bar {
      height: 70px;
      position: absolute;
      top: 0;
      left: 0;
      background: ${(props) =>
        props.mode === "black" ? props.theme.black : props.theme.gold};
      width: 0%;
      z-index: 9;
    }
    .barInner {
      z-index: 9999;
      height: 70px;
      top: 0;
      left: 0;
      position: absolute;
      display: grid;
      justify-items: center;
      align-items: center;
      width: 100%;
      grid-template-columns: 10fr 2fr;
      h2 {
        color: #fff;
        z-index: 9999;
        margin: 0 !important;
        @media (max-width: 800px) {
          font-size: 22px;
        }
        @media (max-width: 500px) {
          font-size: 16px;
          padding-right: 4px;
        }
      }
      .lighter {
        color: #ffffff5c;
      }
    }
  }
  .arrows {
    display: grid;
    justify-items: center;
    align-content: center;
    grid-template-columns: 1fr 1fr;
  }
  .controls-bottom {
    display: grid;
    grid-template-columns: 10fr 2fr;
    grid-template-rows: 50px;
    a {
      color: #fff;
      border: 1px solid ${(props) => props.theme.gold};
      padding: 10px;
      border-radius: 5px;
      background: rgba(9, 51, 52, 0.64);
    }
    .contolButton {
      margin-top: 20px;
    }
  }
`
