import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import BlogListItem from "./BlogListItem"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const Blog = (props) => {
  const { allWpPost } = useStaticQuery(BLOG_LIST_ALL_QUERY)

  return (
    <PageWrapper>
      {allWpPost.edges.map((item) => (
        <BlogListItem item={item.node} key={item.node.id} home={props.home} />
      ))}
    </PageWrapper>
  )
}
export default Blog

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const PageWrapper = styled.div`
  text-align: center;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  max-width: ${(props) => props.theme.maxWidth};
  margin: 60px auto;
  align-content: baseline;
  @media (max-width: 1300px) {
    grid-template-columns: repeat(2, 1fr);
    margin: ${(props) => props.theme.gutter};
  }
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    margin: 0;
  }
`

///////////////////////////////////////
// 🔎 Queries
///////////////////////////////////////
const BLOG_LIST_ALL_QUERY = graphql`
  query BlogListAllContent {
    allWpPost {
      edges {
        node {
          id
          title
          slug
          date(formatString: "MMMM DD, YYYY")
          excerpt
          featuredImage {
            node {
              sourceUrl
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          categories {
            nodes {
              name
              slug
            }
          }
          author {
            node {
              name
              avatar {
                url
                foundAvatar
              }
            }
          }
        }
      }
    }
  }
`
