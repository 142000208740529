import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import SeoComponent from "../components/seoComponent"
import { graphql, useStaticQuery } from "gatsby"
import posed from "react-pose"
import Footer from "../components/footer/footer"
import Header from "../components/header/header"
import Container from "../components/Container"
import styled from "styled-components"
import ContactCard from "../components/home/contactCard"
import SliderControls from "../components/shared/sliderControls"
import HomeLitigationServices from "../components/services/homeLitigation"
import HomeSolicitorsServices from "../components/services/homeSolicitors"
import HomeTeamByLitigation from "../components/team/homeTeamByLitigation"
import HomeTeamBySolicitors from "../components/team/homeTeamBySolicitors"
import Blog from "../components/blog/blog"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const HomePage = () => {
  const { page, sliders } = useStaticQuery(HOME_CONTENT)

  const [isVisible, setIsVisible] = useState(false)
  useEffect(() => {
    let fadeIn = setTimeout(() => setIsVisible(true), 500)
    return () => {
      clearTimeout(fadeIn)
    }
  }, [])

  // SLIDER
  const autoRotate = true
  const [active, setActive] = useState(0)

  const [start, setStart] = useState(true)
  const setStartFalse = () => setStart(false)

  useEffect(() => {
    if (autoRotate) setStart(true)
    setTimeout(setStartFalse, 200)
  }, [active, autoRotate])

  const next = () => {
    setActive((p) => (p === sliders.edges.length - 1 ? 0 : p + 1))
  }

  const prev = () =>
    setActive((p) => (p === 0 ? sliders.edges.length - 1 : p - 1))

  return (
    <Layout>
      <Header />
      <PageAnimationWrapper pose={isVisible ? "visible" : "hidden"}>
        <SeoComponent seo={page.seo} />
        <ServicesWrapper>
          <PageTopHalf>
            <ImageAnimation
              pose={start ? "hidden" : "visible"}
              className="image-animation"
            >
              <MainImage
                bg={sliders.edges[active].node.featuredImage.node.sourceUrl}
              >
                <div className="slider-inner">
                  <SliderControls
                    title={sliders.edges[active].node.title}
                    slug={sliders.edges[active].node.slug}
                    currentSlideNum={active}
                    totalSlides={sliders.edges.length}
                    start={start}
                    next={next}
                    prev={prev}
                  />
                </div>
              </MainImage>
            </ImageAnimation>
          </PageTopHalf>
        </ServicesWrapper>
        <Container>
          <TopContainer>
            <IntroParagraph>
              <h3
                dangerouslySetInnerHTML={{
                  __html: page.acfPageHeader.heading,
                }}
              />
              <div className="seperator" />
              <div
                dangerouslySetInnerHTML={{
                  __html: page.acfPageIntro.introParagraph,
                }}
              />
            </IntroParagraph>
            <ContactCard />
          </TopContainer>
        </Container>
        <Container bg="#eeeeee">
          <CenterTitle>
            <h2>Our Services</h2>
            <div className="center-seperator" />
          </CenterTitle>
          <Services>
            <ServicesSection>
              <div>
                <h3>Litigation</h3>
                <div className="service-seperator" />
                <div
                  dangerouslySetInnerHTML={{
                    __html: page.acfHomepage.litigationText,
                  }}
                />
              </div>
              <div>
                <HomeTeamByLitigation />
              </div>
            </ServicesSection>
            <HomeLitigationServices />
          </Services>
          <Services>
            <ServicesSection>
              <div>
                <h3>Solicitors</h3>
                <div className="service-seperator" />
                <div
                  dangerouslySetInnerHTML={{
                    __html: page.acfHomepage.solicitorsText,
                  }}
                />
              </div>
              <div>
                <HomeTeamBySolicitors />
              </div>
            </ServicesSection>
            <HomeSolicitorsServices />
          </Services>
        </Container>
        <Container>
          <CenterTitle>
            <h2>Newsroom</h2>
            <div className="center-seperator" />
            <Blog showItems="1" home />
          </CenterTitle>
        </Container>
      </PageAnimationWrapper>
      <Footer />
    </Layout>
  )
}
export default HomePage

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const PageAnimationWrapper = posed.div({
  visible: { y: 0, opacity: 1 },
  hidden: { y: 100, opacity: 0 },
})
const ImageAnimation = posed.div({
  visible: { x: 0, opacity: 1 },
  hidden: { x: 0, opacity: 0.5 },
})

const ServicesWrapper = styled.div`
  border-bottom: 5px solid ${(props) => props.theme.gold};
`

const PageTopHalf = styled.div`
  background-color: ${(props) => props.theme.black};
  min-height: 450px;
  width: 100vw;
  height: 700px;
  z-index: 1;
  position: relative;
  .image-animation {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  overflow: hidden;
  @media (max-width: 500px) {
    height: 310px;
  }
`
const MainImage = styled.div`
  background-image: url(${(props) => props.bg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: grid;
  .slider-inner {
    display: grid;
    align-content: end;
    width: 900px;
    margin: 40px auto;
    @media (max-width: 800px) {
      width: 80%;
    }
    @media (max-width: 425px) {
      width: 100%;
    }
  }
  .content {
    margin-left: 240px;
    width: auto;
    background: ${(props) => props.theme.yellow};
    overflow: hidden;
    padding: 30px 60px;
    transition: all 0.3s ease-in-out;
    h2 {
      margin: 0 0 10px 0;
      font-weight: 600;
    }
    p {
      margin: 0;
      color: rgba(0, 0, 0, 0.65);
    }
  }
  .controls {
    padding: 30px;
    justify-self: end;
    width: 100%;
  }
`

const IntroParagraph = styled.div`
  h3 {
    color: ${(props) => props.theme.green};
    margin-bottom: 10px;
  }
  @media (max-width: 500px) {
    h3 {
      font-size: 26px;
    }
  }
`

const Services = styled.div`
  margin-bottom: 40px;
`

const TopContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 20px;
  display: grid;
  grid-gap: 70px;
  grid-template-columns: 1fr 400px;
  h3 {
    margin-top: 40px;
    font-size: 40px;
  }
  p {
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    line-height: 1.5;
  }
  .seperator {
    width: 70px;
    margin: 0px 0 40px 0;
    border-top: 5px solid ${(props) => props.theme.gold};
  }
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-gap: 0px;
    margin-top: 0px;
  }
`
const CenterTitle = styled.div`
  text-align: center;
  h2 {
    color: ${(props) => props.theme.green};
    font-size: 40px;
    margin-top: 30px;
  }
  .center-seperator {
    width: 100px;
    margin: 15px auto 50px auto;
    border-top: 4px solid ${(props) => props.theme.gold};
  }
`

const ServicesSection = styled.div`
  display: grid;
  grid-gap: 100px;
  grid-template-columns: 8fr 4fr;
  h3 {
    color: ${(props) => props.theme.green};
    margin-bottom: 10px;
    font-size: 30px;
  }
  .service-seperator {
    width: 70px;
    margin: 0px 0 40px 0;
    border-top: 4px solid ${(props) => props.theme.gold};
  }
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-gap: 0px;
  }
`

///////////////////////////////////////
// 🔎 Queries
///////////////////////////////////////
const HOME_CONTENT = graphql`
  query HomeDetails {
    page: wpPage(databaseId: { eq: 69 }) {
      seo {
        ...SEO
      }
      acfHomepage {
        litigationText
        solicitorsText
      }
      acfPageIntro {
        introParagraph
      }
      acfPageHeader {
        heading
        subHeading
        compact
        headerImage {
          sourceUrl
        }
      }
    }
    sliders: allWpSlider {
      edges {
        node {
          id
          title
          slug
          featuredImage {
            node {
              sourceUrl
              title
            }
          }
        }
      }
    }
  }

  fragment SEO on WpPostTypeSEO {
    title
    focuskw
    metaDesc
    canonical
    metaKeywords
    metaRobotsNofollow
    metaRobotsNoindex
    opengraphAuthor
    opengraphDescription
    opengraphModifiedTime
    opengraphPublishedTime
    opengraphPublisher
    opengraphSiteName
    opengraphTitle
    opengraphType
    opengraphUrl
    twitterDescription
    twitterTitle
    breadcrumbs {
      text
      url
    }
    opengraphImage {
      altText
      mediaItemUrl
    }
    twitterImage {
      altText
      mediaItemUrl
    }
  }
`
