import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import HomeServiceMember from "./member/HomeServiceMember"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const HomeTeamListingsByLitigation = () => {
  const { litigationTeam } = useStaticQuery(HOME_TEAM_LIST_LITIGATION_QUERY)

  return (
    <WorkListWrapper>
      <p>Our Litigators</p>
      {litigationTeam.edges.map((item) => (
        <HomeServiceMember item={item.node} key={item.node.id} />
      ))}
    </WorkListWrapper>
  )
}
export default HomeTeamListingsByLitigation

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const WorkListWrapper = styled.div`
  display: block;
  max-width: ${(props) => props.theme.maxWidth};
  align-content: baseline;
  @media (max-width: 1300px) {
    grid-template-columns: repeat(2, 1fr);
    margin: ${(props) => props.theme.gutter};
  }
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    margin: 0 0 20px 0;
  }
`

///////////////////////////////////////
// 🔎 Queries
///////////////////////////////////////
const HOME_TEAM_LIST_LITIGATION_QUERY = graphql`
  query HomeTeamByServiceLit {
    litigationTeam: allWpMember(
      filter: { acfTeam: { practiceType: { isLitigation: { eq: true } } } }
    ) {
      edges {
        node {
          id
          title
          slug
          acfTeam {
            education
            image {
              sourceUrl
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            practiceType {
              isSolicitor
              isLitigation
              litigationServices
              solicitorServices
            }
          }
        }
      }
    }
  }
`
