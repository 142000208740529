import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const ButtonArrow = ({ mode, direction, slideShowClick }) => {
  return (
    <Button
      mode={mode}
      direction={direction}
      onClick={() => slideShowClick(direction)}
    >
      <svg
        width="6px"
        height="9px"
        viewBox="0 0 6 9"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="Symbols"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="slider-/-count"
            transform="translate(-8.000000, -47.000000)"
            fill={mode === "dark" ? "#999" : "#ccc"}
            fillRule="nonzero"
          >
            <g id="icon" transform="translate(0.000000, 40.000000)">
              <path
                d="M8.83338348,11.3427893 L12.7447282,7.49123599 C12.9007566,7.33517519 13.1531192,7.33517519 13.30918,7.49123599 L13.5449087,7.72696465 C13.7009695,7.88302545 13.7009695,8.13538805 13.5449087,8.29141643 L10.1482436,11.6250152 L13.5416013,14.958614 C13.6976297,15.1146423 13.6976297,15.367005 13.5416013,15.5230658 L13.3058402,15.7587944 C13.1497794,15.9148552 12.8974493,15.9148552 12.7413885,15.7587944 L8.83007615,11.9072411 C8.67735511,11.7511803 8.67735511,11.4988501 8.83338348,11.3427893 Z"
                id="arrow-left"
              />
            </g>
          </g>
        </g>
      </svg>
    </Button>
  )
}

export default ButtonArrow
///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const Button = styled.button`
  background: ${(props) => props.theme.green};
  display: inline-block;
  line-height: 0;
  padding: 10px;
  margin: 20px 0 0 -1px;
  border: none;
  border-right: 1px solid #072324;
  width: 70px;
  height: 70px;
  transition: all 0.2s ease-in-out;
  transform: rotate(
    ${(props) => (props.direction === "n" ? "180deg" : "0deg")}
  );
  justify-self: ${(props) => (props.direction === "n" ? "start" : "end")};
  &:hover {
    cursor: pointer;
    #icon {
      fill: #fff;
    }
  }
  @media (max-width: 800px) {
    width: 50px;
    height: 50px;
  }
  @media (max-width: 425px) {
    width: 30px;
    height: 30px;
  }
`

///////////////////////////////////////
ButtonArrow.propTypes = {
  mode: PropTypes.string.isRequired,
  direction: PropTypes.string.isRequired,
}

ButtonArrow.defaultProps = {
  mode: "dark",
  direction: "p",
}
