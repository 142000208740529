import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import Service from "./service/homeService"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const HomeLitigationServices = () => {
  const { allWpService } = useStaticQuery(LITIGATION_SERVICES_LIST)

  return (
    <ServicesListWrapper>
      {allWpService.edges.map((item) => (
        <Service item={item.node} key={item.node.id} />
      ))}
    </ServicesListWrapper>
  )
}
export default HomeLitigationServices

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const ServicesListWrapper = styled.div`
  text-align: center;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  max-width: ${(props) => props.theme.maxWidth};
  margin: 20px auto;
  align-content: baseline;
  @media (max-width: 1300px) {
    grid-template-columns: repeat(2, 1fr);
    margin: ${(props) => props.theme.gutter};
  }
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    margin: 0;
  }
`

///////////////////////////////////////
// 🔎 Queries
///////////////////////////////////////
const LITIGATION_SERVICES_LIST = graphql`
  query LitigationServicesList {
    allWpService(
      filter: {
        categories: { nodes: { elemMatch: { databaseId: { eq: 18 } } } }
      }
    ) {
      edges {
        node {
          id
          title
          slug
          acfPageHeader {
            heading
            subHeading
            compact
            headerImage {
              sourceUrl
            }
          }
        }
      }
    }
  }
`
