import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import PhoneIcon from "../../images/phone-icon.svg"
import MailIcon from "../../images/mail-icon.svg"
import LocationIcon from "../../images/location-icon.svg"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const ContactCard = () => (
  <CardContainer>
    <h3>Reach out to us</h3>
    <div className="card-seperator" />
    <div className="contacts">
      <p>
        <img src={PhoneIcon} alt="Phone" />{" "}
        <a href="tel:6044676951">604-467-6951</a>
      </p>
      <p>
        <img src={MailIcon} alt="Mail" />{" "}
        <a href="mailto:reception@mhwp.law">reception@mhwp.law</a>
      </p>
      <p>
        <img src={LocationIcon} alt="Location" />
        22334 McIntosh Ave, Maple Ridge, BC, V2X 3C1.
      </p>
      <p>
        <img src={LocationIcon} alt="Address 2" />
        22366 McIntosh Ave, Maple Ridge, BC V2X 3C1.
      </p>
    </div>
    <ContactButton to={`/contact`}>FULL CONTACT DETAILS</ContactButton>
  </CardContainer>
)
export default ContactCard

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const CardContainer = styled.div`
  background-color: ${(props) => props.theme.green};
  border-radius: 5px;
  padding: 10px 25px;
  h3 {
    text-align: center;
    color: #fff;
    font-size: 28px !important;
    margin-top: 20px;
  }
  a {
    color: #fff;
    display: block;
    margin-bottom: 15px;
    :hover {
      ${(props) => props.theme.gold};
    }
  }
  .card-seperator {
    width: 160px;
    margin: 10px auto 30px auto;
    border-bottom: 2px solid ${(props) => props.theme.gold};
  }
  .contacts {
    p {
      position: relative;
      padding-left: 45px;
      color: #fff;
      font-size: 17px;
    }
    img {
      margin-bottom: 0px;
      width: 30px;
      position: absolute;
      left: 0px;
      top: -3px;
    }
    a {
      font-size: 19px;
      color: #fff;
      padding-bottom: 0px;
    }
    a:hover {
      color: #bd8c02;
    }
    @media (max-width: 1024px) {
      margin-right: 15px;
    }
    @media (max-width: 800px) {
      margin-right: 20px;
    }
  }
  @media (max-width: 800px) {
    padding: 30px 46px;
  }
`

const ContactButton = styled(Link)`
  background-color: #fff;
  border-radius: 5px;
  color: #333 !important;
  font-weight: 500;
  text-align: center;
  padding: 14px 0px;
  transition: all 0.2s ease-in-out;
  :hover {
    background-color: ${(props) => props.theme.gold};
  }
`
